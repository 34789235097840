import type { FC } from 'react';
import styled from '@emotion/styled';
import { useFragment, graphql } from 'react-relay/hooks';
import { Button } from '@pafcloud/base-components';
import { cookies, SELECTED_PROGRAM_ID } from '@pafcloud/cookies';
import { removeNullValues } from '@pafcloud/collection-utils';
import { Breakpoint } from '@pafcloud/style';
import { useFlowRouter } from '@pafcloud/flow-router';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { PROGRAMS_AVAILABLE_FOR_SELECTION } from '../../session-storage';
import { HeroColors } from '../hero-colors';
import type {
  HeroMultipleOffers_contentBlock$data,
  HeroMultipleOffers_contentBlock$key,
} from './__generated__/HeroMultipleOffers_contentBlock.graphql';

const contentBlockFragment = graphql`
  fragment HeroMultipleOffers_contentBlock on HeroMultipleOffersBlock {
    offers {
      programExternalId
      buttonText
      description
    }
  }
`;

const OfferCta = styled.div({
  '--cta-spacing': '16px',

  [Breakpoint.LaptopOrLarger]: {
    '--cta-spacing': '32px',
    width: 'min(640px, 80vw)',
  },

  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginTop: 'var(--cta-spacing)',
});

const Article = styled.article({
  display: 'grid',
  gridTemplateRows: '1fr auto',
  justifyItems: 'center',
  alignItems: 'end',
  flexBasis: '50%',
  width: '50%',
  padding: 'calc(var(--cta-spacing) / 2)',
});

const Text = styled.p({
  textAlign: 'center',
  maxWidth: '100%',
  wordBreak: 'break-word',
  margin: '0.5rem',
  fontWeight: 600,
  color: HeroColors.Text,
});

const CtaButton = styled(Button)(
  {
    gridArea: '1 / 1',
  },
  () => {
    if ($buildEnv.theme === 'momentum') {
      return {
        [Breakpoint.Phone]: {
          fontSize: 'var(--button-large-font-size)',
        },
      };
    }
  },
);

type SelectableOffer = NonNullable<HeroMultipleOffers_contentBlock$data['offers'][number]>;

export type ProgramAvailableForSelection = {
  id: SelectableOffer['programExternalId'];
  text: SelectableOffer['buttonText'];
  description: SelectableOffer['description'];
};

type HeroMultipleOffersComponentProps = {
  contentBlock: HeroMultipleOffers_contentBlock$key;
  onHeroClick: () => void;
};

const HeroMultipleOffers: FC<HeroMultipleOffersComponentProps> = ({ contentBlock, onHeroClick }) => {
  const { offers } = useFragment(contentBlockFragment, contentBlock);
  const { openFlow } = useFlowRouter();

  const handleClick = (programExternalId: string) => {
    onHeroClick();

    const availableOffers: ProgramAvailableForSelection[] = removeNullValues(
      offers.map((offer) => {
        if (offer == null) {
          return null;
        }

        return {
          text: offer.buttonText,
          description: offer.description,
          id: offer.programExternalId,
        };
      }),
    );

    cookies.set(SELECTED_PROGRAM_ID, programExternalId);
    sessionStorage.setItem(PROGRAMS_AVAILABLE_FOR_SELECTION, JSON.stringify(availableOffers));

    openFlow('deposit');
  };

  return (
    <OfferCta>
      {offers.map((offer) => {
        if (offer == null) {
          return null;
        }

        return (
          <Article key={offer.buttonText}>
            <CtaButton
              type="button"
              variant="primary"
              size="hero"
              fullWidth
              onClick={() => handleClick(offer.programExternalId)}
              ctaEffect
            >
              {offer.buttonText}
            </CtaButton>
            {offer.description && <Text>{offer.description}</Text>}
          </Article>
        );
      })}
    </OfferCta>
  );
};

export default HeroMultipleOffers;
